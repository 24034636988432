import { Injectable } from '@angular/core';
import { MqttService, IMqttMessage, IMqttServiceOptions } from 'ngx-mqtt';
import { lastValueFrom, map, Subscription } from 'rxjs';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { Project } from '../models/project/project.model';
import { ApiScenesService } from 'src/app/modules/scenes/api-scenes.service';
import { ApiModesService } from 'src/app/modules/modes/services/http/api-modes.service';
import { ApiAutomationService } from 'src/app/modules/automation/services/http/api-automation.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { DemoService } from '../services/demo.service';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { API_BASE_URL, USE_DALI } from 'src/environments/environment';
import { Room } from '../models/project/room.model';
import { OfflineControllersService } from 'src/app/shared/services/offline-controllers.service'
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { ApiService } from '../http/api.service';
import { AlarmAlertsService } from 'src/app/modules/alarms/services/alarm-alerts.service';



@Injectable({
    providedIn: 'root'
  })
export class MqttProjectService {

    project: Project;

    alarmsInitialized = false;
    mqttBase: string;
    mqttAlarmsSubscription = new Subscription();
    mqttSubscription = new Subscription();
    mqttDaliSubscription = new Subscription();
    commErrorSubscription = new Subscription();
    elementIsFound = false;
    // activeSubscriptions: string[] = [];

    getAlarmsParams = {
      Filtering: '',
      Sorting: '',
      DateTimeFrom: '',
      DateTimeTo: '',
      Levels: [],
      Types: [],
      Confirmed: [0],
      ConfirmUsers: []
    };

    constructor(private mqttService: MqttService,
                private projectService: ProjectService,
                private toastController: ToastController,
                private apiProjectService: ApiProjectService,
                private apiScenesService: ApiScenesService,
                private apiModesService: ApiModesService,
                private apiAutomationService: ApiAutomationService,
                private loadingService: LoadingService,
                private demoService: DemoService,
                private apiDaliProjectService: ApiDaliProjectService,
                private daliProjectService: DaliProjectService,
                private api: ApiService,
                private http: HttpClient,
                private alarmAlertsService: AlarmAlertsService,
                private offlineControllersService: OfflineControllersService) {}

    async initMqttForProject() {
      if (!this.demoService.isDemo()) {
        await this.subscribeToProjectProps();
        this.subscribeToMqttForChanges();
        this.checkForOfflineControllers();
        if (USE_DALI) {
          await this.subscribeToDaliMqtt();
         } else {
          this.apiDaliProjectService.setDaliResolverTrue();
         }
      }
    }

    async subscribeToProjectProps() {
      try {
        let proj: Project = await lastValueFrom(this.apiProjectService.getProject());
        if (!proj) {
          proj = await lastValueFrom(this.apiProjectService.getProject());
        }
      } catch (err) {
        await lastValueFrom(this.apiProjectService.getProject());
      }
      this.project = this.projectService.getProject();
      this.subscribeToEveryProp('listOfBlinds');
      this.subscribeToEveryProp('listOfGeneralEquipment');
      this.subscribeToEveryProp('listOfHvacs');
      this.subscribeToEveryProp('listOfLights');
      this.subscribeToEveryProp('listOfSafetyEquipment');
      this.subscribeToEveryProp('listOfSecurityEquipment');
    }

    getIdOfElementFromTopic(message: IMqttMessage) {
      const indexes = [];
      for (let i = 0; i < message.topic.length; i++) {
        if (message.topic[i] === '/') { indexes.push(i); }
      }
      const changedId =  message.topic.slice(indexes[3] + 1, indexes[4]);
      return changedId;
    }

    subscribeToMqttForChanges() {
      this.mqttSubscription.add(this.mqttService.observe('sys/abas/1/automations/changed').subscribe( (message: IMqttMessage) => {
        this.apiAutomationService.getAutomations().subscribe();
      }));

      this.mqttSubscription.add(this.mqttService.observe('sys/abas/1/automations/+/changed').subscribe( (message: IMqttMessage) => {
        this.apiAutomationService.getAutomations().subscribe();
      }));

      this.mqttSubscription.add(this.mqttService.observe('sys/abas/1/scenes/changed').subscribe( (message: IMqttMessage) => {
        this.apiScenesService.getScenes().subscribe();
      }));

      this.mqttSubscription.add(this.mqttService.observe('sys/abas/1/scenes/+/changed').subscribe( (message: IMqttMessage) => {
        const changedId = this.getIdOfElementFromTopic(message);
        this.apiScenesService.getScene(changedId).subscribe();
      }));

      this.mqttSubscription.add(this.mqttService.observe('sys/abas/1/modes/changed').subscribe( (message: IMqttMessage) => {
        this.apiModesService.getModes().subscribe();
      }));

      this.mqttSubscription.add(this.mqttService.observe('sys/abas/1/modes/+/changed').subscribe( (message: IMqttMessage) => {
        const changedId = this.getIdOfElementFromTopic(message);
        this.apiModesService.getMode(changedId).subscribe();
      }));
    }

    subscribeToEveryProp(arrayName: string) {
      this.project[arrayName].forEach(element => {
        element.equipmentProperties.forEach( prop => {
          this.subscribeToMqtt(prop.inComm.address);
        });
      });
    }

    checkEveryPropForChange(arrayName: string, topic: string, payload: string) {
      this.project[arrayName].some(element => {
        const found = element.equipmentProperties.find( prop => prop.inComm.address === topic);
        if (found) {
          if (found.id !== '123' && found.id !== '122')
          {

          }
          found.value = payload;
          this.elementIsFound = true;
          this.loadingService.removeFromLoading(element.id + found.id);
          this.projectService.updateProjectByMqtt(arrayName, found, element.id);
          this.projectService.updateGeneratedComponentByMqtt(found);
          return true;
        }
      });
    }

    subscribeToMqtt(address: string) {
      this.mqttSubscription.add(this.mqttService.observe(address).subscribe( (message: IMqttMessage) => {
        const index = message.payload.toString().lastIndexOf('|');
        const payload = message.payload.toString().slice(index + 1);
        this.elementIsFound = false;
        if (!this.elementIsFound) {this.checkEveryPropForChange('listOfBlinds', message.topic, payload); }
        if (!this.elementIsFound) {this.checkEveryPropForChange('listOfGeneralEquipment', message.topic, payload); }
        if (!this.elementIsFound) {this.checkEveryPropForChange('listOfHvacs', message.topic, payload); }
        if (!this.elementIsFound) {this.checkEveryPropForChange('listOfLights', message.topic, payload); }
        if (!this.elementIsFound) {this.checkEveryPropForChange('listOfSafetyEquipment', message.topic, payload); }
        if (!this.elementIsFound) {this.checkEveryPropForChange('listOfSecurityEquipment', message.topic, payload); }
      }));

      }

    async subscribeToDaliMqtt() {
      try {
        let proj = await lastValueFrom(this.apiDaliProjectService.getProject())
        if (!proj) {
          proj = await lastValueFrom(this.apiDaliProjectService.getProject())
        }
      } catch (err) {
        await lastValueFrom(this.apiDaliProjectService.getProject())
      }
      this.apiDaliProjectService.getBaseDaliMqttSubscription().subscribe((daliMqttBase) => {
        this.mqttDaliSubscription = this.mqttService.observe(`${daliMqttBase}/#`).subscribe((message: IMqttMessage) => {
          const daliType = message.topic.split('/')[2];
          const id = message.topic.split('/')[4];
          let daliId: string;
          if (daliType === 'light') {
            daliId = `DL-${id}`;
          } else if (daliType === 'rgb') {
            daliId = `DRGB-${id}`;
          } else if (daliType === 'scene') {
            daliId = `DS-${id}`;
          } else if (daliType === 'group') {
            daliId = `DG-${id}`;
          }
          this.daliProjectService.updateDaliProjectByMqtt(daliType, daliId, message.payload.toString());
          this.loadingService.removeFromLoading(daliId);
        });
      });
    }

    checkForOfflineControllers() {
      let commErrorRooms: Array<any> = [];
      this.project['listOfRooms'].forEach( (room: Room) => {
        const roomIDSplit = room.id.split('.');
        const address = 'cli/' + room.driverDesignation + '/' + roomIDSplit[0] + '/' + roomIDSplit[1] + '/' + roomIDSplit[2] + '/+/equipment/status/responseerror';
        this.commErrorSubscription.add(this.mqttService.observe(address).subscribe( (message: IMqttMessage) => {
          const msgSplit = message.topic.split('/');
          const topicID = msgSplit[3] + '.' + msgSplit[4] + '.' + msgSplit[5] + '.' + msgSplit[6];

          const index = message.payload.toString().lastIndexOf('|');
          const payload = message.payload.toString().slice(index + 1);

          let canAddToArray = true;
          commErrorRooms.forEach ((element,index) => {
            if (element[0] === topicID && +payload !== 0) { canAddToArray = false; }
            else if (element[0] === topicID && +payload === 0) {
              canAddToArray = false
              commErrorRooms.splice(index,1);
              this.offlineControllersService.setOfflineControllers(commErrorRooms);
            }
          });
          if (canAddToArray && +payload !== 0) {
            commErrorRooms.push([topicID, +payload]);
            this.offlineControllersService.setOfflineControllers(commErrorRooms);
          }

          const targetRoom = commErrorRooms.find ( roo => {
              if (roo[0] === topicID) {return true; }
            });
          if (targetRoom) {
            if (targetRoom[1] !== +payload) { targetRoom[1] = +payload; }
          }
        }));
      });
    }

        subscribeToMqttAlarms() {
          this.mqttAlarmsSubscription = this.mqttService.observe(`${this.mqttBase}/alarm/+`).subscribe((message: IMqttMessage) => {
              this.presentToast(message);
              const params = new HttpParams()
              .set('pagenumber', '1')
              .set('pagesize', '100');
              this.http.post<any>(API_BASE_URL + '/alarms',  this.getAlarmsParams, {params}).pipe(
                    map( alarms => {
                      this.alarmAlertsService.setAlarms(alarms);
                      return alarms;
                  })).subscribe();

        });
    }

    async presentToast(message: IMqttMessage) {
      const alertLevel = +message.payload.toString().split('|')[2];
      let toastColor = '';
      if (alertLevel === 2) {
          toastColor = 'warning';
      } else if (alertLevel === 3){
          toastColor = 'danger';
      }
      const toast = await this.toastController.create({
        message: message.payload.toString().split('|')[1],
        duration: 2000,
        color: toastColor
      });
      toast.present();
    }

    connect(config: IMqttServiceOptions) {
      this.mqttService.connect(config);
    }

    disconnect() {
      this.mqttService.disconnect();
    }

    initAlarmsService() {
      if (this.alarmsInitialized) {
          return;
      }
      this.api.getBaseMqttSubscription().subscribe(mqttBase => {
              this.mqttBase = mqttBase;
              this.subscribeToMqttAlarms();
          });
      this.alarmsInitialized = true;
  }


    reInitializeAlarmService() {
      if (this.alarmsInitialized) {
        this.unsubscribeFromMqttAlarms();
        this.subscribeToMqttAlarms();
        this.alarmsInitialized = true;
      }

    }

    unsubscribeFromMqttAlarms() {
      if (this.mqttAlarmsSubscription) {
        this.mqttAlarmsSubscription.unsubscribe();
      }
      this.mqttAlarmsSubscription = new Subscription();
      this.alarmsInitialized = false;
    }

    unsubscribeFromMqtt(){
      if (this.mqttSubscription) {
        this.mqttSubscription.unsubscribe();
      }
      if (this.commErrorSubscription) {
        this.commErrorSubscription.unsubscribe();
      }
      if (this.mqttDaliSubscription) {
        this.mqttDaliSubscription.unsubscribe();
      }

      this.mqttSubscription = new Subscription();
      this.mqttDaliSubscription = new Subscription();
      this.commErrorSubscription = new Subscription();
    }
}
