<div *ngIf="mode === 'live'">
  <div class="flex-row flex-center">
    <ion-button [color]="daliLightGroupOnStatus()"  (click)="toggleDaliLightGroupOn()"
      >On</ion-button
    >
    <div class="main-icon flex-row flex-center margin-left20 margin-right20">
      <div *ngIf="!loadingElement(daliLightsGroup.id)" class="i-lightgroup-black-large"></div>
      <div
        *ngIf="loadingElement(daliLightsGroup.id)"
        class="lds-spinner-md margin-bottom4"
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <ion-button [color]="daliLightGroupOffStatus()"  (click)="toggleDaliLightGroupOff()"
      >Off</ion-button
    >
  </div>
  <ion-range
    #rangeValue
    min="{{ sliderOptions.floor }}"
    max="{{ sliderOptions.ceil }}"
    pin="true"
    value="{{ whiteValue }}"
    color="primary"
    (ionChange)="onInputChanged(rangeValue.value)"
    class="margin-top30"
  >
    <ion-icon
      slot="start"
      size="large"
      [style.color]="'#6ba0c4'"
      name="remove-circle"
      (click)="minusClicked()"
      class="clickable margin-right20"
    ></ion-icon>
    <ion-icon
      slot="end"
      size="large"
      [style.color]="'#6ba0c4'"
      name="add-circle"
      (click)="plusClicked()"
      class="clickable margin-left20"
    ></ion-icon>
  </ion-range>
  <div class="text-align-center">{{ daliLightsGroup.name }}</div>

  <div class="content-header flex-row no-wrap">
    <h2 class="ellipsis uppercase">{{'Lights' | translate}}</h2>
  </div>

  <div *ngFor="let daliLight of daliDimmableLightsInGroup" class="margin-top30">
    <app-dimmable-dali-light [daliLight]="daliLight" [mode]="'live'">
    </app-dimmable-dali-light>
  </div>

  <div class="card-grid-container margin-top10">
    <div *ngFor="let daliLight of daliNormalLightsInGroup | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER : user">
      <div class="flex-center flex-row no-wrap">
     <div class="width-full device-card">
      <div class="card-row-of-icons">
        <div *ngIf="daliLight.equipmentProperties[0].value > 0; else lightOff" class="i-lightbulb-orange"></div>
        <ng-template #lightOff> <div class="i-lightbulb-black"></div></ng-template>
        <div  *ngIf="!loadingElement(daliLight.id)"
        class="i-power-bl power-btn-circle"
              [ngClass]="{'power-on': daliLight.equipmentProperties[0].value > 0}"
              (click)="toggleDaliLight(daliLight, $event)"></div>
              <div *ngIf="loadingElement(daliLight.id)" class="lds-spinner-small margin-right5 margin-top5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
      <div class="margin-top-5 ellipsis-2-rows">{{daliLight.name}}</div>
     </div>
      </div>
      </div>
  </div>

</div>


<div *ngIf="mode === 'action'">
  <div class="flex-row flex-center">
    <ion-button color="ocean-blue" (click)="toggleDaliLightGroupOn()"
      >On</ion-button
    >
    <div class="main-icon flex-row flex-center margin-left20 margin-right20">
      <div class="i-lightgroup-black-large"></div>
    </div>
    <ion-button color="ocean-blue" (click)="toggleDaliLightGroupOff()"
      >Off</ion-button
    >
  </div>
  <ion-range
    #rangeValue
    min="{{ sliderOptions.floor }}"
    max="{{ sliderOptions.ceil }}"
    pin="true"
    value="{{ whiteValue }}"
    color="primary"
    (ionChange)="onInputChanged(rangeValue.value)"
    class="margin-top30"
  >
    <ion-icon
      slot="start"
      size="large"
      [style.color]="'#6ba0c4'"
      name="remove-circle"
      (click)="minusClicked()"
      class="clickable margin-right20"
    ></ion-icon>
    <ion-icon
      slot="end"
      size="large"
      [style.color]="'#6ba0c4'"
      name="add-circle"
      (click)="plusClicked()"
      class="clickable margin-left20"
    ></ion-icon>
  </ion-range>
  <!-- <div class="text-align-center">{{ daliLightsGroup.name }}</div> -->
</div>

<div *ngIf="mode === 'condition'">
  <div class="flex-column" >
    <ion-radio-group [(ngModel)]="condition.parameter2">
        <ion-item lines="none">
          <ion-radio labelPlacement="end" slot="start" class="color-radio margin-right40" value="1" (click)="handleBinaryChange($event)">
            <ion-label>{{ 'On' | genComTranslate }}</ion-label>
          </ion-radio>
        </ion-item>

        <ion-item lines="none">
          <ion-radio labelPlacement="end" slot="start" class="color-radio margin-right40" value="0" (click)="handleBinaryChange($event)">
            <ion-label>{{ 'Off' | genComTranslate }}</ion-label>
          </ion-radio>
        </ion-item>
      </ion-radio-group>
  </div>
</div>

<div *ngIf="mode === 'editScene'" >
  <ion-range
  #rangeValue
  min="{{ sliderOptions.floor }}"
  max="{{ sliderOptions.ceil }}"
  pin="true"
  value="{{ whiteValue }}"
  color="primary"
  (ionChange)="onInputChanged(rangeValue.value)"
  class="margin-top30"
>
  <ion-icon
    slot="start"
    size="large"
    [style.color]="'#6ba0c4'"
    name="remove-circle"
    (click)="minusClicked()"
    class="clickable margin-right20"
  ></ion-icon>
  <ion-icon
    slot="end"
    size="large"
    [style.color]="'#6ba0c4'"
    name="add-circle"
    (click)="plusClicked()"
    class="clickable margin-left20"
  ></ion-icon>
</ion-range>
<!-- <div class="text-align-center">{{ daliLightsGroup.name }}</div> -->
<div class="flex-row flex-center margin-top20">
  <ion-button (click)="onAccept()">Accept</ion-button>
</div>
</div>

