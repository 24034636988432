import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
import { lastValueFrom, Observable, Subscription } from 'rxjs';
import { ApiConfigProviderService, ConfigProviderOutput } from 'src/app/core/app-load/api-config-provider.service';
import { ConfigLoaderService } from 'src/app/core/app-load/config-loader.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { DemoService } from 'src/app/core/services/demo.service';
import { NetworkService } from 'src/app/core/services/network.service';
import { demoUser, setDefaultPinDurationInDays, setUseDali, setUseDeviceVisibleToUser } from 'src/environments/environment';
// import { Plugins } from '@capacitor/core';
import { UserService } from 'src/app/core/services/user.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { GenComTranslatePipe } from 'src/app/shared/pipes/gen-com-translate.pipe';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { take } from 'rxjs/operators';
import { App } from '@capacitor/app';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { ObjectConfigurationsService } from 'src/app/core/app-load/object-configurations.service';
import { Configuration } from 'src/app/core/models/configuration.model';


// const { App } = Plugins;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],

})

export class LandingComponent implements OnInit, OnDestroy {

  allConfigsArray$: Observable<ConfigProviderOutput[]> = this.objectConfigurationsService.getAllConfigsArray();

  backButtonPressed = false;
  backButtonPressedTimer: any;
  backButtonSubscription: Subscription;

  constructor(
    private alertController: AlertController,
    private apiConfigProviderService: ApiConfigProviderService,
    private toastController: ToastController,
    private configLoaderService: ConfigLoaderService,
    private router: Router,
    private authService: AuthenticationService,
    private demoService: DemoService,
    private networkService: NetworkService,
    private loadingController: LoadingController,
    private platform: Platform,
    private userService: UserService,
    private projectService: ProjectService,
    private pipe: GenComTranslatePipe,
    private daliProjectService: DaliProjectService,
    private apiProjectService: ApiProjectService,
    private apiDaliProjectService: ApiDaliProjectService,
    private objectConfigurationsService: ObjectConfigurationsService
    ) { }

  ngOnInit(): void {
    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(10, async () => {
      await this.backButtonPress();
    });
    this.fetchConfigsArray();
  }

  async fetchConfigsArray () {
    try {
      const configsArray = await this.configLoaderService.getConfigsArrayFromStorage();
      this.objectConfigurationsService.setAllConfigsArray(configsArray)
    } catch (err) {// no configs
      console.log(err)
    }
  }

  async backButtonPress(){
    if (this.backButtonPressed){
      App.exitApp();
    } else {
      const toast = await this.toastController.create({
        message: this.pipe.transform('Press back button again to exit app.'),
        duration: 2000
      });
      toast.present();
      this.backButtonPressed = true;
      if (this.backButtonPressedTimer) {
        clearTimeout(this.backButtonPressedTimer);
      }
      this.backButtonPressedTimer = setTimeout(() => {
        this.backButtonPressed = false;
      }, 2000);
    }
  }

  async openAbasCodeAlert() {
    const alert = await this.alertController.create({
      header: this.pipe.transform('Enter ABAS code'),
      inputs: [
        {
          name: 'abasCode',
          type: 'text',
          placeholder: this.pipe.transform('Enter ABAS code'),
        }
      ],
      buttons: [
        {
          text: this.pipe.transform('Cancel'),
          role: 'cancel'
        }, {
          text: this.pipe.transform('Enter'),
          handler: (input: {abasCode: string}) => {
            if (!input.abasCode) {
              this.presentToast('Please input ABAS code');
            } else {
              this.handleAbasCode(input.abasCode);
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async handleAbasCode(abasCode: string) {
    const loading = await this.loadingController.create({
      cssClass: 'spiner-color-secondary',
      message: this.pipe.transform('Please wait...'),
    });
    await loading.present();
    try {
      const configProviderOutput: ConfigProviderOutput = await lastValueFrom(this.apiConfigProviderService.getConfig(abasCode));
      if (
        !configProviderOutput ||
        Object.keys(configProviderOutput).length === 0
      ) {
        this.presentToast('Invalid ABAS code. Please try again.');
      }  else if (!Configuration.configUrlsAreValid(configProviderOutput.configuration)) { // check config url validity
        this.presentToast('Error found in ABAS configuration. Try another code.');
      } else {
        setUseDali(configProviderOutput.configuration.USE_DALI);
        setUseDeviceVisibleToUser(configProviderOutput.configuration.USE_DEVICE_VISIBLE_TO_USER);
        setDefaultPinDurationInDays(configProviderOutput.configuration.DEFAULT_PIN_DURATION_IN_DAYS);
        await this.configLoaderService.saveConfigToStorage(configProviderOutput);
        this.configLoaderService.setConfig(configProviderOutput);
        this.networkService.setLocalNetwork(configProviderOutput.configuration);
        await this.networkService.networkCheck(configProviderOutput.configuration);
        this.networkService.initNetworkSwitcher(configProviderOutput.configuration);
        await this.objectConfigurationsService.storePrimaryAbasConfigToArray(configProviderOutput)
        if (this.authService.isValid()) {
          this.router.navigate(['/home']);
        } else {
          this.router.navigate(['/login']);
        }
      }
    } catch (err) {
      this.presentToast('Error connecting to ABAS server');
    }
    loading.dismiss();
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: this.pipe.transform(msg),
      duration: 2000,
    });
    toast.present();
  }


  demoMode() {
      this.projectService.setProject(undefined);
      this.daliProjectService.setProject(undefined);
      this.demoService.setDemo(true);
      this.userService.setUser(demoUser);
      setUseDali(true);
      setUseDeviceVisibleToUser(false);
      setDefaultPinDurationInDays(0);
      this.apiProjectService.getProject().pipe(take(1)).subscribe(() => {
        // this.apiDaliProjectService.setDaliResolverTrue();
        this.router.navigate(['/home']);
      });
      this.apiDaliProjectService.getProject().pipe(take(1)).subscribe(() => {
      });
  }


  async loadConfigPrompt(config: ConfigProviderOutput) {
    const msg = this.pipe.transform(`Confirm switch to`) + ` ${config.configuration.OBJECT_NAME ? config.configuration.OBJECT_NAME : config._id}?`
    const alert = await this.alertController.create({
      header: this.pipe.transform(`Confirm switch`),
      message: msg,
      buttons: [ {
        text: this.pipe.transform('Cancel'),
        role: 'cancel',
      },
        {
          text: this.pipe.transform('Confirm'),
          handler: () => {
            this.objectConfigurationsService.loadConfig(config);
          }
        }
      ],
    });
    await alert.present();
   }

   async onClickDelete(config: ConfigProviderOutput) {
    const msg = this.pipe.transform(`Delete configuration`)+ ` ${config._id}?`
    const alert = await this.alertController.create({
      header: this.pipe.transform('Confirm delete'),
      message: msg,
      buttons: [ {
        text: this.pipe.transform('Cancel'),
        role: 'cancel',
      },
        {
          text: this.pipe.transform('Delete'),
          handler: async () => {
            await this.objectConfigurationsService.onDeleteConfigFromArray(config);
          }
        }
      ],
    });
    await alert.present();
   }

  ngOnDestroy(): void {
    if (this.backButtonSubscription) {
      this.backButtonSubscription.unsubscribe();
    }
  }

}
