import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PropertyType } from 'src/app/core/models/project/property-type.model';
import { take } from 'rxjs/operators';
import { Property } from 'src/app/core/models/project/property.model';

@Injectable({
  providedIn: 'root'
})
export class EquipmentPropertyTypesService {

  private readonly EQUIPMENT_PROPERTY_TYPES_URL: string = 'assets/controller-property-types.json';
  private readonly ADMIN_EQUIPMENT_PROPERTY_TYPES_URL: string = 'assets/admin-settings.json';
  private readonly STATUS_EQUIPMENT_PROPERTY_TYPES_URL: string = 'assets/statuses.json';
  private equipmentPropertyTypes: PropertyType[];
  private equipmentPropertyTypesForAdmin: PropertyType[];
  private equipmentPropertyTypesForStatus: PropertyType[];

  constructor(private http: HttpClient) {
    this.loadEquipmentPropertyTypes();
    this.loadEquipmentPropertyTypesForAdmin();
    this.loadEquipmentPropertyTypesForStatus();
   }

   loadEquipmentPropertyTypes() {
    this.http.get<PropertyType[]>(this.EQUIPMENT_PROPERTY_TYPES_URL)
    .subscribe({
      next: (equipTypesJson: any) => {
      this.equipmentPropertyTypes = equipTypesJson.Definitions;
      },
      error: (error) => {
        if (error === 'Not Found') {
        } else {
          console.log('Device types config error');
        }
      }});
  }

  loadEquipmentPropertyTypesForAdmin() {
    this.http.get<PropertyType[]>(this.ADMIN_EQUIPMENT_PROPERTY_TYPES_URL)
    .subscribe({
      next: (equipTypesJson: any) => {
      this.equipmentPropertyTypesForAdmin = equipTypesJson.Definitions;
      },
      error: (error) => {
        if (error === 'Not Found') {
        } else {
          console.log('Device types config error');
        }
      }});
  }

  loadEquipmentPropertyTypesForStatus() {
    this.http.get<PropertyType[]>(this.STATUS_EQUIPMENT_PROPERTY_TYPES_URL)
    .subscribe({
      next: (equipTypesJson: any) => {
        this.equipmentPropertyTypesForStatus = equipTypesJson.Definitions;
      },
      error: error => {
        if (error === 'Not Found') {
        } else {
          console.log('Device types config error');
        }
      }});
  }


  getEquipmentPropertyType(typeNumber: number): PropertyType {
    //this is for special case of blindsV2
    if (typeNumber >= 11100 && typeNumber <= 11200) {
      return this.lookForBlindsV2Prop(typeNumber);
    } else {
      return this.equipmentPropertyTypes?.find((propertyType: PropertyType) => {
        return (typeNumber >= propertyType.CodeFrom) && (typeNumber <= propertyType.CodeTo);
      });
    }
  }

  getEquipmentPropertyTypeForAdmin(typeNumber: number): PropertyType {
    return this.equipmentPropertyTypesForAdmin?.find((propertyType: PropertyType) => {
      return (typeNumber >= propertyType.CodeFrom) && (typeNumber <= propertyType.CodeTo);
    });
  }

  getEquipmentPropertyTypeForStatus(typeNumber: number): PropertyType {
      return this.equipmentPropertyTypesForStatus?.find((propertyType: PropertyType) => {
        return (typeNumber >= propertyType.CodeFrom) && (typeNumber <= propertyType.CodeTo);
      });

  }

  lookForBlindsV2Prop(typeNumber: number) {
      let prop =  new Property()
      prop.type = typeNumber
    if (Property.isBlindV2Down(prop)) {
      return this.equipmentPropertyTypes?.find((propertyType: PropertyType) => {
        return (typeNumber >= propertyType.CodeFrom) && (typeNumber <= propertyType.CodeTo) && propertyType.Name == 'BlindsV2_down';
      });
    }else if (Property.isBlindV2Up(prop)) {
      return this.equipmentPropertyTypes?.find((propertyType: PropertyType) => {
        return (typeNumber >= propertyType.CodeFrom) && (typeNumber <= propertyType.CodeTo) && propertyType.Name == 'BlindsV2_up';
      });
    }else if (Property.isBlindV2TimeDown(prop)) {
      return this.equipmentPropertyTypes?.find((propertyType: PropertyType) => {
        return (typeNumber >= propertyType.CodeFrom) && (typeNumber <= propertyType.CodeTo) && propertyType.Name == 'BlindsV2_time_down';
      });
    }else if (Property.isBlindV2TimeUp(prop)) {
      return this.equipmentPropertyTypes?.find((propertyType: PropertyType) => {
        return (typeNumber >= propertyType.CodeFrom) && (typeNumber <= propertyType.CodeTo) && propertyType.Name == 'BlindsV2_time_up';
      });
    }else if (Property.isBlindsV2Percent(prop)) {
      return this.equipmentPropertyTypes?.find((propertyType: PropertyType) => {
        return (typeNumber >= propertyType.CodeFrom) && (typeNumber <= propertyType.CodeTo) && propertyType.Name == 'BlindsV2_percent';
      });
    }else if (Property.isBlindV2Direction(prop)) {
      return this.equipmentPropertyTypes?.find((propertyType: PropertyType) => {
        return (typeNumber >= propertyType.CodeFrom) && (typeNumber <= propertyType.CodeTo) && propertyType.Name == 'BlindsV2_direction';
      });
    }
  }

}
