<div *ngIf="mode === 'live'">
  <div class="flex-row flex-center">
    <div class="main-icon flex-row flex-center" (click)="toggleDaliLight()">
      <div *ngIf="!loadingElement(daliLight.id)">
        <div
          *ngIf="getDaliLightValue() === 0"
          class="i-lightbulb-large-black"
        ></div>
        <div
          *ngIf="getDaliLightValue() > 0"
          class="i-lightbulb-large-orange"
        ></div>
      </div>

      <div
        *ngIf="loadingElement(daliLight.id)"
        class="lds-spinner-md margin-bottom4"
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>

  <ion-range
    #rangeValue
    min="{{ sliderOptions.floor }}"
    max="{{ sliderOptions.ceil }}"
    pin="true"
    value="{{ this.daliLight.equipmentProperties[0].value }}"
    color="primary"
    (ionChange)="onInputChanged(rangeValue.value)"
    class="margin-top30"
  >
    <ion-icon
      slot="start"
      size="large"
      [style.color]="'#6ba0c4'"
      name="remove-circle"
      (click)="minusClicked()"
      class="clickable margin-right20"
    ></ion-icon>
    <ion-icon
      slot="end"
      size="large"
      [style.color]="'#6ba0c4'"
      name="add-circle"
      (click)="plusClicked()"
      class="clickable margin-left20"
    ></ion-icon>
  </ion-range>
  <div class="text-align-center">{{ daliLight.name }}</div>
</div>


<div *ngIf="mode === 'action'">
  <div *ngIf="lightIsDimmable">
    <div class="flex-row flex-center">
      <div class="main-icon flex-row flex-center">
        <div>
          <div
            class="i-lightbulb-large-black"
          ></div>
        </div>
      </div>
    </div>

    <ion-range
      #rangeValue
      min="{{ sliderOptions.floor }}"
      max="{{ sliderOptions.ceil }}"
      pin="true"
      value="{{ this.daliLight.equipmentProperties[0].value }}"
      color="primary"
      (ionChange)="onInputChanged(rangeValue.value)"
      class="margin-top30"
    >
      <ion-icon
        slot="start"
        size="large"
        [style.color]="'#6ba0c4'"
        name="remove-circle"
        (click)="minusClicked()"
        class="clickable margin-right20"
      ></ion-icon>
      <ion-icon
        slot="end"
        size="large"
        [style.color]="'#6ba0c4'"
        name="add-circle"
        (click)="plusClicked()"
        class="clickable margin-left20"
      ></ion-icon>
    </ion-range>
    <!-- <div class="text-align-center">{{ daliLight.name }}</div> -->
  </div>

    <div *ngIf="!lightIsDimmable" class="flex-column" >
      <!-- <div class="label">{{daliLight.name}}</div> -->
          <ion-radio-group [value]="action.parameters[1]">
              <ion-item lines="none">
                <ion-radio  labelPlacement="end" slot="start" class="color-radio margin-right40" value="1" (click)="handleBinaryChange($event)">
                  <ion-label>{{ 'On' | genComTranslate }}</ion-label>
                </ion-radio>
              </ion-item>

              <ion-item lines="none">
                <ion-radio labelPlacement="end" slot="start" class="color-radio margin-right40" value="0" (click)="handleBinaryChange($event)">
                  <ion-label>{{ 'Off' | genComTranslate }}</ion-label>
                </ion-radio>
              </ion-item>
            </ion-radio-group>
        </div>
</div>

<div *ngIf="mode === 'condition'">
    <div class="flex-column" >
          <ion-radio-group [(ngModel)]="condition.parameter2">
              <ion-item lines="none">
                <ion-radio labelPlacement="end" slot="start" class="color-radio margin-right40" value="1" (click)="handleBinaryChange($event)">
                  <ion-label>{{ 'On' | genComTranslate }}</ion-label>
                </ion-radio>
              </ion-item>

              <ion-item lines="none">
                <ion-radio labelPlacement="end" slot="start" class="color-radio margin-right40" value="0" (click)="handleBinaryChange($event)">
                  <ion-label>{{ 'Off' | genComTranslate }}</ion-label>
                </ion-radio>
              </ion-item>
            </ion-radio-group>
        </div>
</div>


<div *ngIf="mode === 'editScene'">
  <ion-range
    #rangeValue
    min="{{ sliderOptions.floor }}"
    max="{{ sliderOptions.ceil }}"
    pin="true"
    value="{{ this.daliLight.equipmentProperties[0].value }}"
    color="primary"
    (ionChange)="onInputChanged(rangeValue.value)"
    class="margin-top30"
  >
    <ion-icon
      slot="start"
      size="large"
      [style.color]="'#6ba0c4'"
      name="remove-circle"
      (click)="minusClicked()"
      class="clickable margin-right20"
    ></ion-icon>
    <ion-icon
      slot="end"
      size="large"
      [style.color]="'#6ba0c4'"
      name="add-circle"
      (click)="plusClicked()"
      class="clickable margin-left20"
    ></ion-icon>
  </ion-range>
  <!-- <div class="text-align-center">{{ daliLight.name }}</div> -->
  <div class="flex-row flex-center margin-top20">
    <ion-button (click)="onApply()">Apply</ion-button>
  </div>
</div>
