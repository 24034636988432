<!-- <div class="flex-row space-between margin-bottom20">
  <div>{{getRoom(deviceInSetup)?.name}}</div>
  <div>{{getFloor(deviceInSetup)?.name}}</div>
</div> -->
<div *ngIf="step === 'select property'">
  <div class="label">{{ 'select_device_property_for_new_condition' | translate }}</div>
  <div *ngFor="let equipmentProperty of equipmentProperties | filterBlindsV2">
    <div (click)="selectEquipProp(equipmentProperty)" class="property-container">{{ getEquipmentPropertyName(equipmentProperty)}} </div>
  </div>
</div>

<div *ngIf="step === 'select condition'">
  <div *ngIf="deviceIsDali">
    <app-rgbw-light *ngIf="daliLightIsRgb(deviceInSetup)" [daliCondition]="deviceInSetupCondition" [daliRgbLightInput]="deviceInSetup" [mode]="'condition'" (deviceCondition$)="conditionChanged($event)"></app-rgbw-light>
    <app-dimmable-dali-light *ngIf="daliLightIsNormal(deviceInSetup)" [daliCondition]="deviceInSetupCondition"  [daliLight]="deviceInSetup" [mode]="'condition'" (deviceCondition$)="conditionChanged($event)"></app-dimmable-dali-light>
    <app-dimmable-light-group *ngIf="idDaliGroup(deviceInSetup)" [daliCondition]="deviceInSetupCondition"  [daliLightsGroup]="deviceInSetup" [mode]="'condition'" (deviceCondition$)="conditionChanged($event)"></app-dimmable-light-group>
  </div>
  <ng-template *ngIf="!deviceIsDali" #equipmentPropertyContainer></ng-template>
  <div class="margin-top40 flex-row flex-end">
    <ion-button size="default" class="square-secondary margin-right5" color="ocean-blue" (click)="onCancel()">{{ 'cancel' | translate }}</ion-button>
     <ion-button size="default" class="square-secondary" (click)="onApply()" color="ocean-blue">{{ 'apply' | translate }}</ion-button>
   </div>
</div>

