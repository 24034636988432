import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { ComponentMode } from 'src/app/core/models/equipment-property-component-to-load.model';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { PinService } from 'src/app/shared/services/pin.service';
import { ModalPinComponent } from '../../modal-pin/modal-pin.component';
import { ModalBlindsv2SettingsComponent } from '../../modal-blindsv2-settings/modal-blindsv2-settings.component';

@Component({
  selector: 'app-equipment-blinds-v2',
  templateUrl: './equipment-blinds-v2.component.html',
  styleUrls: ['./equipment-blinds-v2.component.scss']
})
export class EquipmentBlindsV2Component implements OnInit, OnDestroy {
  @Input() parentDevice: Device;
  @Input() propertyArray: Property[];
  @Input() title: string;
  @Input() mode: ComponentMode;

  blindsV2_up: Property;
  blindsV2_down: Property;
  blindsV2_percent: Property;
  blindsV2_time_up: Property;
  blindsV2_time_down: Property;
  blindsV2_direction: Property;

  loadingSubscription: Subscription;
  loadingElements = [];
  sliderValues = new BehaviorSubject(null);
  sliderSubscription: Subscription;
  inputDelay = 1000;
  sliderDisabled = false;
  constructor(private pinService: PinService,
              private loadingService: LoadingService,
              private modalController: ModalController,
              private apiProjectService: ApiProjectService) { }


  ngOnInit(): void {
    this.blindsV2_up = this.propertyArray.find( prop => Property.isBlindV2Up(prop))
    this.blindsV2_down = this.propertyArray.find( prop => Property.isBlindV2Down(prop))
    this.blindsV2_percent = this.propertyArray.find( prop => Property.isBlindsV2Percent(prop))
    this.blindsV2_time_up = this.propertyArray.find( prop => Property.isBlindV2TimeUp(prop))
    this.blindsV2_time_down = this.propertyArray.find( prop => Property.isBlindV2TimeDown(prop))
    this.blindsV2_direction = this.propertyArray.find( prop => Property.isBlindV2Direction(prop))
    if (this.mode === 'live') {
      this.loadingSub();
      this.sliderSubscription = this.sliderValues.pipe(
        debounceTime(this.inputDelay),
        distinctUntilChanged(),
      ).subscribe((newSliderValue) => {
        if (newSliderValue !== null) {
          this.loadingService.addToLoading(this.parentDevice.id + this.blindsV2_percent.id.toString());
          if (this.pinService.pinIsSet() && this.blindsV2_percent.security.requirePinToActivate) {
              this.apiProjectService.changeProperty(this.parentDevice.id, this.blindsV2_percent.type, newSliderValue.toString(), this.pinService.getPin()).subscribe(() => {
                this.blindsV2_percent.value = newSliderValue;
                this.disableSlider();
              });
          } else {
              this.apiProjectService.changeProperty(this.parentDevice.id, this.blindsV2_percent.type, newSliderValue.toString()).subscribe(() => {
                this.blindsV2_percent.value = newSliderValue;
                this.disableSlider();
              });
          }
        }
    });
    }
  }
  

  propertyClicked(prop: Property) {
    // this.buttonClicked = true;
    if (prop.security.requirePinToActivate && !this.pinService.pinIsSet()) {
      this.openSpecialPinModal(prop);
    } else {
      this.handleLiveChange(prop);
    }
  }

  async openSpecialPinModal(property: Property) {
    const modal = await this.modalController.create({
      component: ModalPinComponent,
      cssClass: 'pin-modal-container',
      backdropDismiss: true,
      showBackdrop: true,
    });
    modal.onDidDismiss()
    .then((resp) => {
      if (resp?.data?.choice) {
        this.pinService.setPin(resp?.data?.pin).subscribe( response => {
          if (response === true) {
            this.handleLiveChange(property);
          }
        });} 
  });
    return await modal.present();
  }

  handleLiveChange(property: Property) {
      this.loadingService.addToLoading(this.parentDevice.id + property.id.toString());
      if (this.pinService.pinIsSet() && property.security.requirePinToActivate) {
          this.apiProjectService.changeProperty(this.parentDevice.id, property.type, '1', this.pinService.getPin()).subscribe( (response) => {
          });
      } else {
          this.apiProjectService.changeProperty(this.parentDevice.id, property.type, '1').subscribe();
      }
  }

  blindsV2SettingChange(property: Property) {
    this.loadingService.addToLoading(this.parentDevice.id + property.id.toString());
    if (this.pinService.pinIsSet() && property.security.requirePinToActivate) {
        this.apiProjectService.changeProperty(this.parentDevice.id, property.type, property.value, this.pinService.getPin()).subscribe( (response) => {
        });
    } else {
        this.apiProjectService.changeProperty(this.parentDevice.id, property.type, property.value).subscribe();
    }
  }

  async openBlindsV2Settings() {
    const modal = await this.modalController.create({
      component: ModalBlindsv2SettingsComponent,
      cssClass: 'blindsv2-settings-modal-container',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        blindsV2_time_up: this.blindsV2_time_up,
        blindsV2_time_down: this.blindsV2_time_down,
        blindsV2_direction: this.blindsV2_direction
      }
    });
    modal.onDidDismiss()
    .then((resp) => {
      if (resp.data) {
        this.blindsV2SettingChange(this.blindsV2_time_up)
        this.blindsV2SettingChange(this.blindsV2_time_down)
        this.blindsV2SettingChange(this.blindsV2_direction)
      }
  });
    return await modal.present();
  }

  

  onSliderChanged(rangeValue) {
      if (this.mode === 'live') {
        if (Number(rangeValue) !== Number(this.blindsV2_percent.value)) {
          this.blindsV2_percent.value = rangeValue;
          this.sliderValues.next( this.blindsV2_percent.value);
        }
      } else if (this.mode === 'action') {
        // this.daliLight.equipmentProperties[0].value = rangeValue;
        // this.action.parameters[1] = this.daliLight.equipmentProperties[0].value;
        // this.deviceAction$.emit(this.action);
      }

  }

  minusClicked() {
      if (this.mode === 'live' && !this.sliderDisabled) {
        if (this.blindsV2_percent.value > 0) {
          this.blindsV2_percent.value--;
          this.sliderValues.next( this.blindsV2_percent.value);
        }
      // } else if (this.mode === 'action') {
      //   this.daliLight.equipmentProperties[0].value--;
      //   this.action.parameters[1] = this.daliLight.equipmentProperties[0].value;
      //   this.deviceAction$.emit(this.action);
      // }
  }
}

  plusClicked() {
      if (this.mode === 'live' && !this.sliderDisabled) {
        if (this.blindsV2_percent.value < 100) {
          this.blindsV2_percent.value++;
          this.sliderValues.next( this.blindsV2_percent.value);
        }
      }
      //  else if (this.mode === 'action') {
      //   this.daliLight.equipmentProperties[0].value++;
      //   this.action.parameters[1] = this.daliLight.equipmentProperties[0].value;
      //   this.deviceAction$.emit(this.action);
      // }
  }

  loadingSub() {
    this.loadingElements = this.loadingService.getLoadingElements();
    this.loadingSubscription = this.loadingService.loadingChanged.subscribe( response => {
      this.loadingElements = this.loadingService.getLoadingElements();
    });
  }


  disableSlider() {
    this.sliderDisabled = true;
    setTimeout( () => {
      this.sliderDisabled = false
    },5000)
  }

  loadingElement(id: string) {
    if (this.loadingElements.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }

}
