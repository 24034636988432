import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthenticationService } from './../authentication/authentication.service';
import { UserService } from '../services/user.service';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { User } from 'src/app/core/models/user/user.model';
import { ILoginResponse } from '../models/login-response.interface';
import { DemoService } from '../services/demo.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard  {

    constructor(private authService: AuthenticationService,
                private router: Router,
                private userService: UserService,
                private messagingService: MessagingService,
                private demoService: DemoService) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if (this.demoService.isDemo()) {
            return true;
        } else {
            if (this.authService.isValid()) {
                let user = this.userService.getUser();
                if (user === undefined) {
                    await lastValueFrom(this.authService.getUserByToken()).then(u => {user = u; }).catch(err => {});
                }
                user = Object.assign(new User(), user);
                if (Object.keys(user).length === 0) {
                    this.authService.logout();
                    this.messagingService.setMessage('Access denied!');
                    this.routeToLogin(state);
                    return false;
                }
                return true;
            } else {
                let response = <ILoginResponse>{};
                if (this.authService.getAccessToken() && this.authService.getRefreshToken()) {
                    this.authService.setRefreshTokenInProgress(true);
                    await lastValueFrom(this.authService.refreshAccessToken()).then(r => response = r).catch(() => this.routeToLogin(state));
                    this.authService.setRefreshTokenInProgress(false);
                } else {
                    this.routeToLogin(state);
                    return false;
                }
                if (!Object.keys(response).length) {
                    this.routeToLogin(state);
                    return false;
                }
                this.userService.setUser(response.user);
                this.authService.setAccessToken(response.tokens.accessToken);
                this.authService.setRefreshToken(response.tokens.refreshToken);

                if (!this.authService.isValid()) {
                    this.routeToLogin(state);
                    return false;
                }
                return true;
            }
        }
    }



    routeToLogin(state) {
        this.router.navigate(['/login'], {
            queryParams: {
                return: state.url
            }
        });
    }
}
