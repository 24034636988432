<div *ngIf="mode === 'live'">
    <!--   BLINDS ORDER IS REVERSED ON DRIVER
           SO IT IS REVERSED ON FRONTEND TO MATCH -->
           <div class="flex-flow-row-nowrap margin-left50">
               <div class="flex-column-center margin-top50">
                 <div (click)="propertyClicked(blindsV2_up)" class="blinds-prop margin-bottom10">
                   <div>
                     {{ 'blinds_up' | translate }}
                   </div>
                   <div  *ngIf="!loadingElement(parentDevice.id + blindsV2_up.id.toString())" class="i-blinds-up-big margin-top5 margin-bottom5"></div>
                     <div *ngIf="loadingElement(parentDevice.id + blindsV2_up.id.toString())"  class="lds-spinner margin-top5 margin-bottom5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                     <div>
                       {{ (+blindsV2_up.value === 0 ? blindsV2_up.data.nameForFalse : blindsV2_up.data.nameForTrue) | genComTranslate}}
                     </div>
                 </div>
             
                 <div (click)="propertyClicked(blindsV2_down)" class="blinds-prop">
                   <div>
                     {{ 'blinds_down' | translate }}
                   </div>
                   <div *ngIf="!loadingElement(parentDevice.id + blindsV2_down.id.toString())" class="i-blinds-down-big margin-top5 margin-bottom5"> </div>
                   <div *ngIf="loadingElement(parentDevice.id + blindsV2_down.id.toString())"  class="lds-spinner margin-top5 margin-bottom5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                   <div>
                     {{ (+blindsV2_down.value === 0 ? blindsV2_down.data.nameForFalse : blindsV2_down.data.nameForTrue) | genComTranslate}}
                   </div>
                 </div>
               </div>
                <div *ngIf="blindsV2_percent" class="range pos-relative">
                    <div class="flex-flow-row-nowrap slider-position">
                        <i (click)="minusClicked()" class="i-minus-rounded minus-rotate"></i>
                        <input type="range" min="0" max="100" name="volume" class="width300px" (change)=onSliderChanged($event.target.value) value="{{ blindsV2_percent.value }}" [disabled]="sliderDisabled">
                        <i (click)="plusClicked()" class="i-plus-rounded minus-rotate"></i>
                    </div>
                    <div class="pos-absolute slider-num"> {{ blindsV2_percent.value }} </div>
                </div>
           </div>

           <div *ngIf="blindsV2_time_up || blindsV2_time_down || blindsV2_direction" class="margin-top50 margin-left50">
            <ion-button size="default" color="ocean-blue" (click)="openBlindsV2Settings()"><i class="i-settings-sm-w"></i> {{ 'Settings' | translate }}</ion-button>
            </div>
   </div>