import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Project } from '../models/project/project.model';
import { ApiProjectService } from '../../modules/project/services/http/api-project.service';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';



@Injectable({
    providedIn: 'root'
})
export class ProjectResolve  {

  constructor(private apiProjectService: ApiProjectService,
              private apiDaliProjectService: ApiDaliProjectService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return this.apiProjectService.isProjectLoaded() && this.apiDaliProjectService.isDaliProjectLoaded()
  }
}
