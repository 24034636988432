import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthenticationService } from './../../core/authentication/authentication.service';
import { User } from 'src/app/core/models/user/user.model';
import { UserService } from './../../core/services/user.service';
import { API_BASE_URL, USE_DALI } from 'src/environments/environment';
import { MqttProjectService } from 'src/app/core/app-load/mqtt-project.service';
import { NetworkService } from 'src/app/core/services/network.service';
import { ConfigLoaderService } from 'src/app/core/app-load/config-loader.service';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { DemoService } from 'src/app/core/services/demo.service';
import { GenComTranslatePipe } from 'src/app/shared/pipes/gen-com-translate.pipe';
import { ConfigProviderOutput } from 'src/app/core/app-load/api-config-provider.service';
import { Configuration } from 'src/app/core/models/configuration.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { Observable } from 'rxjs';
import { ObjectConfigurationsService } from 'src/app/core/app-load/object-configurations.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: User = new User();
  returnUrl = '';
  forgotPassword = false;
  passwordHasReset = false;
  emailForgotenPassword: string;
  loading = false;

  fullConfig$ = this.configLoaderService.fullConfig$.asObservable();
  allConfigsArray$: Observable<ConfigProviderOutput[]> = this.objectConfigurationsService.getAllConfigsArray();

  constructor(private auth: AuthenticationService,
              private userService: UserService,
              private router: Router,
              private route: ActivatedRoute,
              private http: HttpClient,
              private mqttProjectService: MqttProjectService,
              private networkService: NetworkService,
              private configLoaderService: ConfigLoaderService,
              public platform: Platform,
              private demoService: DemoService,
              private loadingController: LoadingController,
              private alertController: AlertController,
              private pipe: GenComTranslatePipe,
              private authService: AuthenticationService,
              private projectService: ProjectService,
              private daliProjectService: DaliProjectService,
              private objectConfigurationsService: ObjectConfigurationsService
              ) { }

  ngOnInit() {
    if (this.platform.is('mobile') && !this.demoService.isDemo()) {
      this.configLoaderService.checkConfigForUpdates();
    }
    this.route.queryParams.subscribe(
      (params: Params) => {
        this.returnUrl = params.return || '/home';
        if (this.returnUrl === '/login') {
          this.returnUrl = '/home';
        }
        }
    );
  }

  onLogin(form: NgForm) {
    if (form.valid) {
      this.user.email = this.user.email.trim();
      this.loading = true;
      this.auth.login(this.user).subscribe({
         next: async (response) => {
          this.userService.setUser(response.user);
          this.mqttProjectService.unsubscribeFromMqtt();
          const config = this.configLoaderService.getConfig();
          const networkType = this.networkService.getNetworkType().getValue();
          if (networkType === 'local') {
            this.mqttProjectService.connect(config.configuration.MQTT_LOCAL_SETTINGS)
          } else {
            this.mqttProjectService.connect(config.configuration.MQTT_OVPN_SETTINGS)
          }
          await this.mqttProjectService.initMqttForProject();
          this.loading = false;
          this.router.navigate([this.returnUrl]);
        },
        error: (err) => {
          this.loading = false;
        }
      });
    }
  }

  isLoggedIn(): boolean {
    return this.auth.isValid();
  }

  backToLogin() {
    this.forgotPassword = false;
    this. passwordHasReset = false;
    this.emailForgotenPassword = '';
  }

  onResetPassword(form: NgForm) {
    if (form.valid) {
      this. passwordHasReset = true;
      this.apiResetPassword(this.emailForgotenPassword).subscribe();
    }
  }

  apiResetPassword(email: string) {
    const params = new HttpParams().set('email', email);
    return this.http.get<User[]>(API_BASE_URL + '/login/reset', {params});
  }


  continue() {
    if (this.returnUrl) {
      this.router.navigate([this.returnUrl]);
    } else {
      this.router.navigate(['/home']);
    }
  }

  async openDeleteConfigModal() {
    const alert = await this.alertController.create({
      header: this.pipe.transform('Return to landing page?'),
      // message: this.pipe.transform('Delete configuration and return to landing page?'),
      buttons: [ {
        text: this.pipe.transform('Cancel'),
        role: 'cancel',
      },
        {
          text: this.pipe.transform('Confirm'),
          handler: () => {
            this.onDeleteConfig();
          }
        }
      ],
    });
    await alert.present();
   }

  async onDeleteConfig() {
    const loading = await this.loadingController.create({
      cssClass: 'spiner-color-secondary',
      message: this.pipe.transform('Returning to landing page...'),
    });
    loading.present();
    this.networkService.setUsingNetworkSwitcher(false);

    const fullConfig: ConfigProviderOutput = this.configLoaderService.getConfig();
    const networkSwitcherPingTimeout: number = Configuration.getNetworkSwitcherPingTimeout(fullConfig.configuration);

    // wait for all network switcher requests to complete or time out
    setTimeout(async () => {
      this.userService.unsetUser();
      this.authService.deleteAccessAndRefreshToken(); // this.authService.logout();
      this.mqttProjectService.unsubscribeFromMqtt();
      try {
        this.mqttProjectService.disconnect()
      } catch (err) {
        console.log(err)
      }
      await this.configLoaderService.deleteConfigFromStorage();
      this.projectService.setProject(null);
      if (USE_DALI) {
        this.daliProjectService.setProject(null);
      }
      this.configLoaderService.resetCheckedConfigForUpdates();
      // this.networkService.setDemoNetwork();
      loading.dismiss();
      this.router.navigate(['/landing']);
    }, networkSwitcherPingTimeout);
  }

}
