<div *ngIf="mode === 'action'" class="flex-column">
  <div class="flex-column">
    <div class="label margin-bottom20">{{ equipmentProperty.name }}</div>
  </div>
  <ion-radio-group [value]="action.parameters[1]">
    <ion-item *ngFor="let option of outputModeOptions" lines="none">
      <ion-radio
        labelPlacement="end"
        slot="start"
        class="color-radio margin-right40"
        value="{{ option.value }}"
        (click)="handleActionChange($event)">
        <ion-label>{{ option?.name | genComTranslate }}</ion-label>
      </ion-radio>
    </ion-item>
  </ion-radio-group>
</div>

<div *ngIf="mode === 'condition'" class="flex-column">
  <div class="flex-column" >
     <div class="label margin-bottom20">{{ equipmentProperty.name }}</div>
   </div>
     <ion-radio-group [value]="condition.parameter2">
       <ion-item *ngFor="let option of outputModeOptions" lines="none">
        <ion-radio
          labelPlacement="end"
          slot="start"
          class="color-radio margin-right40"
          value="{{ option.value }}"
          (click)="handleConditionChange($event)">
          <ion-label>{{ option?.name | genComTranslate }}</ion-label>
        </ion-radio>
      </ion-item>
     </ion-radio-group>
</div>

