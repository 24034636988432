import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ApiConfigProviderService, ConfigProviderOutput } from 'src/app/core/app-load/api-config-provider.service';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { IStoredToken } from 'src/app/modules/settings/components/object-configurations/object-configurations.component';
import { GenComTranslatePipe } from 'src/app/shared/pipes/gen-com-translate.pipe';
import { setDefaultPinDurationInDays, setUseDali, setUseDeviceVisibleToUser, USE_DALI } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { Configuration } from '../models/configuration.model';
import { NetworkService } from '../services/network.service';
import { UserService } from '../services/user.service';
import { ConfigLoaderService } from './config-loader.service';
import { MqttProjectService } from './mqtt-project.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectConfigurationsService {
  private allConfigsArray$ = new BehaviorSubject<ConfigProviderOutput[] | undefined>(undefined);

  constructor(
    private configLoaderService: ConfigLoaderService,
    private loadingController: LoadingController,
    private networkService: NetworkService,
    private userService: UserService,
    private authService: AuthenticationService,
    private mqttProjectService: MqttProjectService,
    private projectService: ProjectService,
    private daliProjectService: DaliProjectService,
    private router: Router,
    private pipe: GenComTranslatePipe,
    private toastController: ToastController,
    private apiConfigProviderService: ApiConfigProviderService,
    ) { }

  setAllConfigsArray(configs: ConfigProviderOutput[]) {
    this.allConfigsArray$.next(configs)
  }

  getAllConfigsArray() {
    return this.allConfigsArray$.asObservable()
  }

  async onDeleteConfigFromArray(config: ConfigProviderOutput) {
    const newConfigsArray = this.allConfigsArray$.getValue().filter((c)=> {
      return c._id !== config._id
    })
    await this.configLoaderService.saveConfigsArrayToStorage(newConfigsArray)
    const allConfigsArray = await this.configLoaderService.getConfigsArrayFromStorage();
    this.setAllConfigsArray(allConfigsArray)
    await this.deleteTokenFromArray(config._id)
 }

 async deleteTokenFromArray(configId: string) {
  try { // multiple tokens file is available
    const tokensArray: IStoredToken[] =  await this.configLoaderService.getTokensArrayFromStorage();
    const newTokensArray = tokensArray.filter((t)=> {
      return t.configId !== configId
    })
    await this.configLoaderService.saveTokensArrayToStorage(newTokensArray);
  }
  catch (err) { // no multiple tokens file available, nothing to delete
    console.log(err)
  }
 }

 async switchConfig(config: ConfigProviderOutput) { // switch if config is already loaded
  // store tokens with config id
  const currentlyUsedConfig = this.configLoaderService.getConfig();
  const tokenToStore: IStoredToken = {
    configId: currentlyUsedConfig._id,
    accessToken: this.authService.getAccessToken(),
    refreshToken: this.authService.getRefreshToken()
  }
  await this.storeTokenToArray(tokenToStore)

  // clear old config load new config
  const loading = await this.loadingController.create({
    cssClass: 'spiner-color-secondary',
    message: this.pipe.transform('Switching configuration...'),
  });
  loading.present();
  this.networkService.setUsingNetworkSwitcher(false);
  const networkSwitcherPingTimeout: number = Configuration.getNetworkSwitcherPingTimeout(currentlyUsedConfig.configuration);

  // wait for all network switcher requests to complete or time out
  setTimeout(async () => {
    // delete everything
    this.userService.unsetUser();
    this.authService.deleteAccessAndRefreshToken();
    this.mqttProjectService.unsubscribeFromMqtt();
    this.mqttProjectService.disconnect();
    await this.configLoaderService.deleteConfigFromStorage();
    this.projectService.setProject(null);
    this.configLoaderService.resetCheckedConfigForUpdates();
    if (USE_DALI) {
      this.daliProjectService.setProject(null);
    }
    // set new config
    setUseDali(config.configuration.USE_DALI);
    setUseDeviceVisibleToUser(config.configuration.USE_DEVICE_VISIBLE_TO_USER);
    setDefaultPinDurationInDays(config.configuration.DEFAULT_PIN_DURATION_IN_DAYS);
    await this.configLoaderService.saveConfigToStorage(config);
    this.configLoaderService.setConfig(config);
    this.networkService.setLocalNetwork(config.configuration);
    await this.networkService.networkCheck(config.configuration);
    this.networkService.initNetworkSwitcher(config.configuration);

    const allTokensArray: IStoredToken[] =  await this.configLoaderService.getTokensArrayFromStorage()
    const tokenForConfig = allTokensArray.find((t)=> {
      return t.configId === config._id
    })
    // check token validity
    if (tokenForConfig && this.authService.tokenIsValid(tokenForConfig.accessToken)) {// token available and valid
      this.authService.setAccessToken(tokenForConfig.accessToken)
      this.authService.setRefreshToken(tokenForConfig.refreshToken)
      await lastValueFrom(this.authService.getUserByToken())
      this.mqttProjectService.unsubscribeFromMqtt();
      // const config = this.configLoaderService.getConfig();
      const networkType = this.networkService.getNetworkType().getValue();
      if (networkType === 'local') {
        this.mqttProjectService.connect(config.configuration.MQTT_LOCAL_SETTINGS)
      } else {
        this.mqttProjectService.connect(config.configuration.MQTT_OVPN_SETTINGS)
      }
      await this.mqttProjectService.initMqttForProject();
      await loading.dismiss();
      this.router.navigate(['/home']);

    } else { // not token or token invalid
      await loading.dismiss();
      this.router.navigate(['/login']);
    }
  }, networkSwitcherPingTimeout);
}

async loadConfig(config: ConfigProviderOutput) { // load config if no config is previously loaded
  const loading = await this.loadingController.create({
    cssClass: 'spiner-color-secondary',
    message: this.pipe.transform('Switching configuration...'),
  });
  loading.present();
  this.networkService.setUsingNetworkSwitcher(false);

  // wait for all network switcher requests to complete or time out
  setTimeout(async () => {

    // set new config
    setUseDali(config.configuration.USE_DALI);
    setUseDeviceVisibleToUser(config.configuration.USE_DEVICE_VISIBLE_TO_USER);
    setDefaultPinDurationInDays(config.configuration.DEFAULT_PIN_DURATION_IN_DAYS);
    await this.configLoaderService.saveConfigToStorage(config);
    this.configLoaderService.setConfig(config);
    this.networkService.setLocalNetwork(config.configuration);
    await this.networkService.networkCheck(config.configuration);
    this.networkService.initNetworkSwitcher(config.configuration);

    try { // checki if tokens are stored
      const allTokensArray: IStoredToken[] =  await this.configLoaderService.getTokensArrayFromStorage()
      const tokenForConfig = allTokensArray.find((t)=> {
        return t.configId === config._id
      })
      // check token validity

      if (tokenForConfig && this.authService.tokenIsValid(tokenForConfig.accessToken)) {// token available and valid
        this.authService.setAccessToken(tokenForConfig.accessToken)
        this.authService.setRefreshToken(tokenForConfig.refreshToken)
        await lastValueFrom(this.authService.getUserByToken())
        this.mqttProjectService.unsubscribeFromMqtt();
        // const config = this.configLoaderService.getConfig();
        const networkType = this.networkService.getNetworkType().getValue();
        if (networkType === 'local') {
          this.mqttProjectService.connect(config.configuration.MQTT_LOCAL_SETTINGS)
        } else {
          this.mqttProjectService.connect(config.configuration.MQTT_OVPN_SETTINGS)
        }
        await this.mqttProjectService.initMqttForProject();
        await loading.dismiss();
        this.router.navigate(['/home']);


      } else { // not token or token invalid
        await loading.dismiss();
        this.router.navigate(['/login']);
      }

    } catch (err) { // no tokens stored
      await loading.dismiss();
        this.router.navigate(['/login']);
    }

  }, 3000);
}

async storeTokenToArray (tokenToStore: IStoredToken) {
  try { // multiple tokens file is available
    const tokensArray: IStoredToken[] =  await this.configLoaderService.getTokensArrayFromStorage()

    const tokenToStoreIndex = tokensArray.findIndex((t)=> {
      return t.configId === tokenToStore.configId
    })

    if (tokenToStoreIndex !== -1) {
      tokensArray[tokenToStoreIndex] = tokenToStore;
    } else {
      tokensArray.push(tokenToStore)
    }
    await this.configLoaderService.saveTokensArrayToStorage(tokensArray);
  }
  catch (err) { // no multiple tokens file available
    const newTokensList = [tokenToStore]
    await this.configLoaderService.saveTokensArrayToStorage(newTokensList)
  }
}

async handleAbasCode(abasCode: string) {
  const newConfig: ConfigProviderOutput = await lastValueFrom(this.apiConfigProviderService.getConfig(abasCode));

  if ( !newConfig || Object.keys(newConfig).length === 0) {
    this.presentToast('Invalid ABAS code. Please try again.');
    return
  }
  const currentlyUsedConfig: ConfigProviderOutput = this.configLoaderService.getConfig();
  if (newConfig._id === currentlyUsedConfig._id) {
    this.presentToast('Code already loaded and in use');
    return;
  }

  if (!Configuration.configUrlsAreValid(newConfig.configuration)) { // check for invalid config urls
    this.presentToast('Error found in ABAS configuration. Try another code.');
    return
  }
  await this.storeSecondaryAbasConfigToArray(newConfig);
  const configsArray = await this.configLoaderService.getConfigsArrayFromStorage();
  this.setAllConfigsArray(configsArray)
}


private async  presentToast(msg: string) {
  const toast = await this.toastController.create({
    message: this.pipe.transform(msg),
    duration: 2000,
  });
  toast.present();
}

async storeSecondaryAbasConfigToArray(newConfig: ConfigProviderOutput) { // used when other config is active

  // check if some configs are already stored
  try  { // configs array file is available
    const configs: ConfigProviderOutput[] = await this.configLoaderService.getConfigsArrayFromStorage();
    const configAlreadyInStorage = configs.some((c)=> {
      return c._id === newConfig._id
    })
    if (configAlreadyInStorage) {
      this.presentToast('Code already entered and stored. Choose from list below');
      return;
    }
    let newConfigsArray: ConfigProviderOutput[];
    const currentlyUsedConfig: ConfigProviderOutput = this.configLoaderService.getConfig();
    if (configs.length === 0) {
      newConfigsArray = [currentlyUsedConfig, newConfig]
    } else {
      newConfigsArray = [...configs, newConfig]
    }

    await this.configLoaderService.saveConfigsArrayToStorage(newConfigsArray)

  } catch (err) { // multiple configs file does NOT exist
    const currentlyUsedConfig: ConfigProviderOutput = this.configLoaderService.getConfig();
    const newConfigsArray = [currentlyUsedConfig, newConfig]
    await this.configLoaderService.saveConfigsArrayToStorage(newConfigsArray)
  }
}

async storePrimaryAbasConfigToArray(newConfig: ConfigProviderOutput) { // used when this is only config

  // check if some configs are already stored
  try  { // configs array file is available
    const configs: ConfigProviderOutput[] = await this.configLoaderService.getConfigsArrayFromStorage();
    const configAlreadyInStorage = configs.some((c)=> {
      return c._id === newConfig._id
    })
    if (configAlreadyInStorage) {
      return;
    }
    let newConfigsArray: ConfigProviderOutput[];
    // const currentlyUsedConfig: ConfigProviderOutput = this.configLoaderService.getConfig();
    if (configs.length === 0) {
      newConfigsArray = [newConfig]
    } else {
      newConfigsArray = [...configs, newConfig]
    }

    await this.configLoaderService.saveConfigsArrayToStorage(newConfigsArray)

  } catch (err) { // multiple configs file does NOT exist
    // const currentlyUsedConfig: ConfigProviderOutput = this.configLoaderService.getConfig();
    const newConfigsArray = [newConfig]
    await this.configLoaderService.saveConfigsArrayToStorage(newConfigsArray)
  }
}

}



